<template>
  <div>
      <h3 v-if="userData">{{userData.user.profile.name}}</h3>
      <b-card title="Ações do usuário">
        <div class="row">
          <div class="col text-center">
            <button class="action" v-on:click="$refs['name-modal'].show()">TROCAR NOME</button>
          </div>
          <div class="col text-center">
            <button class="action warning-button" v-on:click="showMsgBoxOne">RESETAR SENHA</button>
          </div>
          <div class="col text-center">
            Calma amiguinhos
          </div>
        </div>
      </b-card>
    <div id="user-data">
      <div class="row" v-if="userData">
        <b-card title="Qtd depositos hoje" class="col-3">
          <div class="big-number text-success">
            {{userData.todayDepositsCount}}
          </div>
        </b-card>
        <b-card title="Total de dep hoje" class="col-3">
          <div class="big-number text-success">
            {{userData.todayDepositsSum}}
          </div>
        </b-card>
        <b-card title="Qtd de saques hoje" class="col-3">
          <div class="big-number text-danger">
            {{userData.todayWithdrawsCount}}
          </div>
        </b-card>
        <b-card title="Saques hoje" class="col-3">
          <div class="big-number text-danger">
            {{userData.todayWithdrawsSum}}
          </div>
        </b-card>
        <b-card title="Qtd bonus hoje" class="col-3">
          <div class="big-number">
            -
          </div>
        </b-card>
        <b-card title="Bonus Hoje" class="col-3">
          <div class="big-number">
            -
          </div>
        </b-card>
        <b-card title="Compras hoje" class="col-3">
          <div class="big-number text-warning">
            {{userData.todayBuysSum}}
          </div>
        </b-card>
        <b-card title="Saldo em carteira" class="col-3">
          <div class="big-number text-warning">
            {{userData.user.wallet.balance}}
          </div>
        </b-card>
      </div>
      <div class="row">
        <b-card title="Depositos confirmados" class="col-6 mv-types">
          <div>
            <b-table stripe :items="this.userData.deposits" :fields="depositFields"></b-table>
          </div>
        </b-card>
        <b-card title="Saques confirmados" class="col-6 mv-types">
          <div >
            <b-table stripe :items="this.userData.withdraws" :fields="depositFields"></b-table>
          </div>
        </b-card>
      </div>
      <div class="row">
        <b-card title="Ultimas transações" class="col-12 mv-types">
          <div >
            <b-table stripe :items="this.userData.transactions" :fields="depositFields"></b-table>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal id="name-modal" ref="name-modal" hide-footer>
      <template #modal-title>
        Mudar nome de usuário
      </template>
      <div class="d-block text-center">
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Digite o novo nome"
              label-for="name"
            >
              <b-form-input
                id="value"
                v-model="newName"
                name="name"
                placeholder="Digite o novo nome"
                type="text" @keydown.space="(event) => event.preventDefault()"
              />
            </b-form-group>
            <b-button class="mt-3" block @click="changeName()">Mudar Nome</b-button>
          </b-form>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BCard,BRow,BForm,BModal, BCol, BTable,BFormGroup, BFormInput, BCardText, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { dateFromUTCToLocale, dateToMySQLFormat } from '@/@core/utils/utils'
export default {
  components: {
    BCard,
    BForm,
    BCardText,
    BCardActions,
    BButton,
    BListGroup, 
    BListGroupItem,
    BTable,BModal,
    BRow, BCol, BFormGroup, BFormInput,
  },
  data(){
    return{
      userData: false,
      depositFields : ['description', 'value', 'status', 'created_at'],
      newName : "",
    }
  },
  created(){
    this.getClientInfo();
  },
  methods:{
    getClientInfo(){
      this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/getClientInfos',{
        userId: this.$route.params.clientId,
      })
      .then(res => {
        var data = res.data;
        data.deposits = data.deposits.map((x)=> {
          x.created_at = dateToMySQLFormat(dateFromUTCToLocale(new Date(x.created_at)));
          x.updated_at = dateToMySQLFormat(dateFromUTCToLocale(new Date(x.updated_at)));
          return x;
        });

        data.transactions = data.transactions.map((x)=> {
          x.created_at = dateToMySQLFormat(dateFromUTCToLocale(new Date(x.created_at)));
          x.updated_at = dateToMySQLFormat(dateFromUTCToLocale(new Date(x.updated_at)));
          return x;
        });


        data.withdraws = data.withdraws.map((x)=> {
          x.created_at = dateToMySQLFormat(dateFromUTCToLocale(new Date(x.created_at)));
          x.updated_at = dateToMySQLFormat(dateFromUTCToLocale(new Date(x.updated_at)));
          return x;
        });

          this.userData = data;
          console.log(data)
      })
    },
    changeName(){
      this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/newName',{
        userId: this.$route.params.clientId,
        name: this.newName,
      })
      .then(res => {
          alert(res.data)
          this.$refs['name-modal'].hide()

      })
    },

     showMsgBoxOne() {
        this.boxOne = ''
        this.$bvModal.msgBoxConfirm('Deseja realmente resetar a senha?')
          .then(value => {
            this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/resetPassword',{
              userId: this.$route.params.clientId,
            })
            .then(res => {
                alert(res.data)
            })
          })
          .catch(err => {
            // An error occurred
          })
      },
  }
}
</script>

<style>
  .big-number{
    font-size:25px;
    font-weight: bold;
    text-align: center;
  }
  .card-title{
    text-align: center;
  }
  .mv-types{
    max-height: 300px;
    overflow: scroll;
  }

  .action{
    border:1px solid;
    border-radius: 5px;
    background: transparent;
    color:#fff;
    border-color: #fff;
    padding: 10px;

  }

  .warning-button{
    color:yellow;
    border-color: yellow;
  }
</style>